import React, { Component } from 'react';
import '../StandardPage/StandardPage.css';
import MetaTags from 'react-meta-tags';
import { majorProvisionsService } from '../../services/MajorProvisionsService';
import ReactPaginate from 'react-paginate';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import archiveJson from '../../resources/archiveYears.json';

class MajorProvision extends Component {
  constructor(props) {
    super(props);

    this.state = {
      majorProvisions: [],
      searchMajorProvision: {
        title: '',
        archiveYear: 2025
      },
      archiveYears: archiveJson,
      pagination: {}
    };
  }

  componentWillMount() {
    majorProvisionsService
      .getAll()
      .then(jsonResponse => {
        this.setMajorProvisionsState(jsonResponse);
      })
      .catch(error => console.log(error));
  }

  handlePageClick = selectedPage => {
    this.search(selectedPage.selected + 1);
  };

  setMajorProvisionsState = response => {
    let majorProvisions = { ...this.state.majorProvisions };
    let pagination = { ...this.state.pagination };
    majorProvisions = response.major_provisions;
    pagination = response.pagination;

    this.setState({ majorProvisions });
    this.setState({ pagination });
  };

  updateField = event => {
    const field = event.target.name;
    const searchMajorProvision = this.state.searchMajorProvision;
    searchMajorProvision[field] = event.target.value;

    this.setState(
      {
        searchMajorProvision
      },
      () => {
        if (field === 'archiveYear') {
          this.search();
        }
      }
    );
  };

  search = (pageNumber = 1) => {
    majorProvisionsService
      .search(this.state.searchMajorProvision, pageNumber)
      .then(jsonResponse => {
        this.setMajorProvisionsState(jsonResponse);
      })
      .catch(error => console.log(error));
  };

  render() {
    return (
      <div className="standard">
        <MetaTags>
          <title>Dispozitiile Primarului</title>
          <meta
            name="description"
            content="Dispozitiile Primarului Frătăuții Vechi. Aici sunt prezentate dispozitiile primarului semnate in  Frătăuții Vechi."
          />
          <meta
            name="keywords"
            content="primaria Frătăuții Vechi, Frătăuții Vechi, institutii si insitutii subordonate, stimulente educationale si insertie, proiecte cu finantare externa, certificate de urbanism, rapoarte de activitate, publicatii de casatorie, regulament si comisii, proiecte de hotarari, indemnizatii crestere copil, procese verbale sedinte, transparenta venituri salariale, starea civila, asistenta sociala, consiliul local, interes public, hotarari adoptate, inregistrare casatorie, proiecte depuse, Suceava, Bucovina, trasee montane, achizitie, achizitii, registrul agricol, anunturi, nastere, acte nastere, proiecte finalizate, autorizatii de construire, evenimente, informatii financiare, ajutoare incalzire, jurist, conducere, consilieri locali, dispozitiile primarului"
          />
          <meta property="og:title" content="Dispozitiile Primarului" />
        </MetaTags>
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Prezentare > </h4>
            <h4 className="standard__generic">Dispozițiile Primarului</h4>
          </div>
          <h2 className="standard__main-title">Dispozițiile Primarului</h2>
        </div>

        <div>
          <div className="standard__wrapper">
            <div className="standard__input-box standard__mp-input-box">
              <input
                className="standard__input standard__mp-input"
                label="Cautare Document"
                placeholder="Căutare Document"
                name="title"
                ovalue={this.state.searchMajorProvision.title}
                onChange={this.updateField}
              />
              <img
                src="/images/icons/search.svg"
                className="standard__search-icon mariage-publications__search-icon"
                alt="search-icon"
                onClick={() => this.search()}
              />
              <div className="standard__input-box mariage-publications__input-box lead-page__input-box">
                <FormControl>
                  <NativeSelect
                    value={this.state.searchMajorProvision.archiveYear}
                    onChange={this.updateField}
                    inputProps={{
                      name: 'archiveYear'
                    }}
                  >
                    {this.state.archiveYears.map(archive => (
                      <option value={archive.value}>{archive.name}</option>
                    ))}
                  </NativeSelect>
                </FormControl>
              </div>
            </div>
            <div className="standard__cards standard__projects-wrapper-few">
              {this.state.majorProvisions.length > 0 ? (
                this.state.majorProvisions.map(majorProvision => (
                  <div
                    className="standard__individual-card"
                    key={majorProvision.id}
                  >
                    <div className="standard__number-box">
                      <h4 className="standard__dispozition-number">
                        Nr. {majorProvision.provision_number}
                      </h4>
                    </div>
                    <h3 className="standard__card-title">
                      {majorProvision.title}
                    </h3>
                    <h4 className="standard__card-date">
                      {majorProvision.data_dispozitie}
                    </h4>
                    <h4
                      className="standard__see-project"
                      onClick={() =>
                        window.open(
                          `${process.env.REACT_APP_API_URL}${majorProvision.document.url}`
                        )
                      }
                    >
                      Vizualizează Document
                    </h4>
                    <img
                      src="/images/icons/file.svg"
                      className="standard__card-img"
                      alt="file"
                      onClick={() =>
                        window.open(
                          `${process.env.REACT_APP_API_URL}${majorProvision.document.url}`
                        )
                      }
                    />
                  </div>
                ))
              ) : (
                <div className="standard__no-projects-box">
                  <p className="standard__no-projects">
                    Nu sunt rezultate pentru căutare.
                  </p>
                </div>
              )}
            </div>
          </div>
          {this.state.pagination.last_page > 1 && (
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={this.state.pagination.last_page}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              onPageChange={this.handlePageClick}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
            />
          )}
        </div>
      </div>
    );
  }
}

export default MajorProvision;
