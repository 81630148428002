import React from 'react';
import '../StandardPage/StandardPage.css';
import ReactPaginate from 'react-paginate';
import { financialSituationService } from '../../services/FinancialSituationService';
import '../StandardPage/StandardPage.css';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import archiveJson from '../../resources/archiveYears.json';

class PaymentSituation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      financialSituations: [],
      searchFinancialSituation: {
        title: '',
        archiveYear: 2025
      },
      archiveYears: archiveJson,
      financialSitutationPagination: {}
    };
  }

  componentWillMount() {
    financialSituationService
      .getAll()
      .then(response => {
        this.setFinancialSituationState(response);
      })
      .catch(error => console.log(error));
  }

  setFinancialSituationState = response => {
    let financialSituations = { ...this.state.financialSituations };
    let financialSitutationPagination = {
      ...this.state.financialSitutationPagination
    };
    financialSituations = response.financial_situations;
    financialSitutationPagination = response.pagination;

    this.setState({ financialSituations });
    this.setState({ financialSitutationPagination });
  };

  handleFinancialSituationPageClick = selectedPage => {
    this.search(selectedPage.selected + 1);
  };
  updateField = event => {
    const field = event.target.name;
    const searchFinancialSituation = this.state.searchFinancialSituation;
    searchFinancialSituation[field] = event.target.value;

    this.setState(
      {
        searchFinancialSituation
      },
      () => {
        if (field === 'archiveYear') {
          this.search();
        }
      }
    );
  };

  search = (pageNumber = 1) => {
    financialSituationService
      .search(this.state.searchFinancialSituation, pageNumber)
      .then(jsonResponse => {
        this.setFinancialSituationState(jsonResponse);
      })
      .catch(error => console.log(error));
  };

  render() {
    return (
      <div className="standard">
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Buget > </h4>
            <h4 className="standard__generic">Informații Financiare</h4>
          </div>
          <h2 className="standard__main-title">Situația Plăților</h2>
        </div>
        <div className="standard__wrapper">
          <div className="standard__input-box standard__mp-input-box">
            <input
              className="standard__input standard__mp-input"
              label="Cautare Document"
              placeholder="Căutare Document"
              name="title"
              ovalue={this.state.searchFinancialSituation.title}
              onChange={this.updateField}
            />
            <img
              src="/images/icons/search.svg"
              className="standard__search-icon mariage-publications__search-icon"
              alt="search-icon"
              onClick={() => this.search()}
            />
            <div className="standard__input-box mariage-publications__input-box lead-page__input-box">
              <FormControl>
                <NativeSelect
                  value={this.state.searchFinancialSituation.archiveYear}
                  onChange={this.updateField}
                  inputProps={{
                    name: 'archiveYear'
                  }}
                >
                  {this.state.archiveYears.map(archive => (
                    <option value={archive.value}>{archive.name}</option>
                  ))}
                </NativeSelect>
              </FormControl>
            </div>
          </div>
          <div
            className={
              'standard__cards ' +
              (this.state.financialSituations.length <= 2
                ? 'standard__projects-wrapper-few'
                : '')
            }
          >
            {this.state.financialSituations.length > 0 ? (
              this.state.financialSituations.map(financialSituation => (
                <div
                  className="standard__individual-card"
                  key={financialSituation.id}
                >
                  <h3 className="standard__card-title">
                    {financialSituation.title}
                  </h3>
                  <h4
                    className="standard__see-project"
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_API_URL}/${financialSituation.document.url}`
                      )
                    }
                  >
                    Vizualizează
                  </h4>
                  <img
                    src="/images/icons/file.svg"
                    className="standard__card-img standard__card-img-local"
                    alt="file"
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_API_URL}${financialSituation.document.url}`
                      )
                    }
                  />
                </div>
              ))
            ) : (
              <div className="standard__no-projects-box">
                <p className="standard__no-projects">
                  Informații in curs de actualizare.
                </p>
              </div>
            )}
          </div>
          {this.state.financialSitutationPagination.last_page > 1 && (
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={this.state.financialSitutationPagination.last_page}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              onPageChange={this.handleFinancialSituationPageClick}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
            />
          )}
        </div>
      </div>
    );
  }
}

export default PaymentSituation;
